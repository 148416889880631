import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../layout/Component/Navbar';
import Footer from '../layout/Component/Footer';
function DefaultLayout() {
    return (
        <div>
            <Navbar/>
            <div className='outlet_wrapper'>
                <Outlet />
            </div>
            <Footer/>
        </div>
    )
}

export default DefaultLayout
import React, { useEffect ,useRef} from 'react'
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom'
import AboutUsSection from './sections/aboutUsSection';
import homePageArray from '../router/homePageArray';
import tech1 from '../assests/media/images/tech1.jpg';
import tech2 from '../assests/media/images/tech2.jpg'
import tech3 from '../assests/media/images/tech3.jpg'
import tech4 from '../assests/media/images/tech4.jpg'
import progress from '../assests/media/images/progress.png'
import progress1 from '../assests/media/images/progress1.png'
import progress2 from '../assests/media/images/progress2.png'
import progress3 from '../assests/media/images/progress3.png'
import progress4 from '../assests/media/images/progress4.png'
import progress5 from '../assests/media/images/progress5.png'
import progress6 from '../assests/media/images/progress6.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import FormSection from './sections/FormSection';
import ServicesSection from './sections/servicesSection';
import ChooseUsSection from './sections/ChooseUsSection';

function Home() {
  const formSectionRef = useRef(null);
  const navigate = useNavigate()
  const services = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  const scrollToFormSection = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToPortfolioPage=()=>{
    navigate('/portfolio')
  }


  return (
    <>
      <div className='home'>
        <div className='bg-home px-3'>
          <div className='content-div'>
            <p className='main-heading mb-0'>Innovative Flutter</p>
            <p className='main-description mb-0'>Your Team for Cutting-Edge
              Development and Design</p>
            <p className='pb-5 main-content mb-0'>For 10 years, we simplify the digital transformation of businesses
              with next-generation custom software solutions. And are trusted by 100+ customers for empowering them with agility & automation to lead the evolving world today and tomorrow.
            </p>
            <div>
              <button className='py-2 bg-white border-0 rounded-pill blue' onClick={scrollToFormSection}>Let’ Work Together</button>
              <button className='py-2 mt-2 mt-md-2 ms-0 ms-md-2 bg-transparent font-white border-white rounded-pill text-white' onClick={goToPortfolioPage}>See Portfolio</button>
            </div>
          </div>
        </div>
      </div>
      <ServicesSection/>
      <AboutUsSection />
      <div className='statestic-div'>
        <div className='inner-container'>
          <Slider {...services}>
          {
            homePageArray.statesticArray.map((x, i) => {
              return (
                <div className='demo'>
                  <div className='outer-container3'>
                    <div className={`outer-container bg-bblue `}>
                      <div className='outer-container2 text-center'>
                        <p className='blue mb-2 s-title'>{x.title}</p>
                        <p className='mb-0 text-dark description'>{x.description}</p>
                        <div className='outer-container4 d-flex justify-content-center align-content-center align-items-center sky'>
                          {x.numbers}
                          <i className='fa fa-plus fs-6 ps-1'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          </Slider>
        </div>
      </div>
      <ChooseUsSection/>
      <div className='technologies-div'>
        <div className='inner-container text-center'>
          <p className='main-description pb-4'>Technologies we work with</p>
          <div className='row row-cols-2 row-cols-md-4 gapping align-items-center justify-content-center '>
            <img src={tech1} className='col img-fluid imgs'></img>
            <img src={tech2} className='col img-fluid imgs'></img>
            <img src={tech3} className='col img-fluid imgs'></img>
            <img src={tech4} className='col img-fluid imgs'></img>
          </div>
        </div>
      </div>
      <div className='progress-div spacing text-center'>
        <p className='main-description mb-0'>
          Our Work Process
        </p>
        <p className='main-content mb-0 grey'>
          The process steps on creating our awesome and beautiful projects.
        </p>
        <div className='p-3'>
          <img src={progress} className='img-fluid'>
          </img>
        </div>
        <div className='position-relative'>
          <div className='long-arrow'></div>
          <div className='row row-cols-3 row-cols-md-6 px-0 px-lg-4 pt-3 p-relative'>
            <div className='col'>
              <div className='bg'>
                <div className='numbers'>1</div>
                <img className='img-fluid' src={progress1}></img>
              </div>
              <p className='py-3 m-0'>Meet</p>
            </div>
            <div className='col'>
              <div className='bg arrow'>
                <div className='numbers'>2</div>
                <FontAwesomeIcon className='grey' icon={faArrowRight} />
                <img className='img-fluid ' src={progress2}></img>
              </div>
              <p className='py-3 m-0'>Plan</p>
            </div>
            <div className='col'>
              <div className='bg'>
                <div className='numbers'>3</div>
                <FontAwesomeIcon className='grey' icon={faArrowRight} />
                <img className='img-fluid' src={progress3}></img>
              </div>
              <p className='py-3 m-0'>Design</p>
            </div>
            <div className='col'>
              <div className='bg'>
                <div className='numbers'>4</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <FontAwesomeIcon className='grey d-block d-md-none' icon={faArrowLeft} />
                <img className='img-fluid' src={progress4}></img>
              </div>
              <p className='py-3 m-0'>Develop</p>
            </div>
            <div className='col'>
              <div className='bg'>
                <div className='numbers'>5</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <FontAwesomeIcon className='grey d-block d-md-none' icon={faArrowLeft} />
                <img className='img-fluid' src={progress5}></img>
              </div>
              <p className='py-3 m-0'>Testing</p>
            </div>
            <div className='col'>
              <div className='bg'>
                <div className='numbers'>6</div>
                <FontAwesomeIcon className='grey d-none d-md-block' icon={faArrowRight} />
                <img className='img-fluid' src={progress6}></img>
              </div>
              <p className='py-3 m-0'>Launch</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={formSectionRef} id='form-section'>
      <FormSection />
      </div>
    </>

  )
}

export default Home




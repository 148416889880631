import React from 'react'
import homePageArray from '../../router/homePageArray'
import Slider from 'react-slick';

function ChooseUsSection() {
    const choosesUs = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 1,
            },
          },
        ],
      }
  return (
    <div className='whyChooseUs spacing text-center'>
        <p className='main-description mb-0'>
          Why Choose Us
        </p>
        <p className='main-content mb-0 grey'>
          We believe in building strong, long-term relationship with our clients by providing ongoing support and guidance. Choose us
          for a partner that is invested in your success and committed to delivering results that exceed your expectations.
        </p>
        <div className='pt-3 chooseUs-div d-block d-lg-none'>
          <Slider {...choosesUs}>
            {
              homePageArray.whyChooseUsArray.map((x, i) => {
                return (
                  <>
                    <div className='outer-container' key={i}>
                      <div className=' rounded-4 container'>
                        <div className='image-container d-flex justify-content-center align-items-center'>
                          <div className='inner-circle d-flex justify-content-center align-items-center'>
                            <img className='img-fluid' src={x.image}></img>
                          </div>
                        </div>
                        <div className='bg-white inner-div p-3'>
                          <p className='text-dark mb-0 title pt-4 pb-2 choose-title'>{x.title}</p>
                          <p className='grey description'>{x.description}</p>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </Slider>
        </div>
        <div className='pt-3 chooseUs-div d-none d-lg-flex row row-cols-lg-3 '>
          {
            homePageArray.whyChooseUsArray.map((x, i) => {
              return (
                <>
                  <div className='col outer-container' key={i}>
                    <div className=' rounded-4 container'>
                      <div className='image-container d-flex justify-content-center align-items-center'>
                        <div className='inner-circle d-flex justify-content-center align-items-center'>
                          <img className='img-fluid' src={x.image}></img>
                        </div>
                      </div>
                      <div className='bg-white inner-div p-3'>
                        <p className='text-dark mb-0 title pt-4 pb-2 choose-title'>{x.title}</p>
                        <p className='grey description'>{x.description}</p>
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          }
        </div>
      </div>
  )
}

export default ChooseUsSection
import React from 'react'
import aboutUs from '../../assests/media/images/aboutUs.png'
import { useNavigate } from 'react-router-dom'


export default function AboutUsSection() {
  const navigate = useNavigate();
  const NavigateToPortfolio=()=>{
    navigate('/portfolio')
  }
  return (
    <div className='spacing row row-cols-1 row-cols-md-2 pb-2'>
      <div className='d-flex flex-column justify-content-center pe-0 pe-md-3 order-2 order-md-1'>
        <p className='main-description mb-0'>About Us</p>
        <h3 className='aboutUs-heading'>Innovative Flutter</h3>
        <p className='aboutUs-content grey'>Innovetive Flutter is a leading IT company specializing in 
innovative Mobile Applicatio  n Design(UI/UX Designing) & 
Mobile Application Devlopment, solutions and cutting-edge 
technology services. With a commitment to excellence, our 
team of experts collaborates with clients to transform their 
digital vision into reality, ensuring growth, efficiency, and 
sustainability." </p>
<button className='rounded-pill bg-bblue btn-width text-white mb-4 mb-md-0' onClick={NavigateToPortfolio}>See Portfolio</button>
      </div>
      <div className=' order-1 order-md-2 d-flex'>
        <img className='img-fluid align-self-center' src={aboutUs}></img>
      </div>
    </div>
  )
}

import AboutUs from "../layout/AboutUs";
import ContactUs from "../layout/ContactUs";
import Home from "../layout/Home";
import Portfolio from "../layout/Portfolio";
import Services from "../layout/Services";
import WhyUs from "../layout/WhyUs";

const pageRoutes =[
    {
        path:'/',
        component:<Home/>,
        exact:true,
    },
    {
        path:'/aboutus',
        component:<AboutUs/>,
        exact:true,
    },
    {
        path:'/services',
        component:<Services/>,
        exact:true,
    },
    {
        path:'/portfolio',
        component:<Portfolio/>,
        exact:true,
    },
    {
        path:'/whyus',
        component:<WhyUs/>,
        exact:true,
    },
    {
        path:'/contactus',
        component:<ContactUs/>,
        exact:true,
    },
]

export default pageRoutes
import React from 'react';
import Slider from 'react-slick';
import service1 from '../../assests/media/images/service1.png'
import service2 from '../../assests/media/images/service2.png'
import service3 from '../../assests/media/images/service3.png'

function ServicesSection() {
    const services = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
        ],
      }
  return (
    <div className='our-services text-center spacing'>
    <p className='main-description mb-0'>
      Our Services
    </p>
    <p className='main-content mb-3 grey'>"Crafting Your Digital Presence Innovative Flutter specializes in developing sleek, user-centric apps and websites,
      intertwining advanced technology with innovative design to propel your brand in the digital landscape."
    </p>
    <div className='pt-3 service-div'>
      <Slider {...services}>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service1}></img>
            <p>UI/UX Designing</p>
          </div>
        </div>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service2}></img>
            <p>Application developer</p>
          </div>
        </div>
        <div className='service-cards'>
          <div className='py-3 services-content'>
            <img src={service3}></img>
            <p>Android Developer</p>
          </div>
        </div>
      </Slider>
    </div>
  </div>
  )
}

export default ServicesSection
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faLinkedinIn, faBehance, faDribbble } from '@fortawesome/free-brands-svg-icons'
import { useForm } from 'react-hook-form';
import  axios  from 'axios';

function FormSection() {
  const { register, handleSubmit, formState: { errors } ,reset} = useForm();
  const [obj, setObj] = useState({
    firstName:'',
    lastName:'',
    email:'',
    mobileNumber:'',
    message:''
  })
  const sendEmail = (data) => {
    const headers = {
      'Content-Type': 'application/json'
    }
    console.log(headers)
    return axios.post('https://innovativeflutter.com/ups/email-api.php',data,{headers})
        .then(res => res.data).catch(res => res.data)
}
  const onSubmit = async(data) => {
    console.log(data)
    const { firstName, lastName, email, mobileNumber, message } = data;
    
    const emailData = {
      to_email: 'bhoomi6296@gmail.com',
      subject: 'Innovative Flutter : Inquiry',
      body: JSON.stringify(data),
      from_email: 'savani.uts@hotmail.com'
  };
  try {
    const response = await sendEmail(emailData);
    console.log(response)
    reset()
  } catch (error) {
    console.log((error))
  } 
  };

  return (
    <div id='form' className='form-spacing formSection'>
      <div className='gradient'>
        <div className='inner-space d-flex'>
          <div className='contact-div'>
            <p className=' main-description'>Contact Info</p>
            <div className='description'>
              <p className='m-0 d-flex'>
                <FontAwesomeIcon className='icons' icon={faLocationDot} />
                <p className='m-0 d-flex align-items-center'>
                  406, Elita Squre, VIP circle,
                  Uttran, Surat- 394105
                </p>
              </p>
              <p className='m-0 d-flex align-items-center'>
                <FontAwesomeIcon className='icons' icon={faEnvelope} />
                <p className='m-0'>
                  info@innovativeflutter.com
                </p>
              </p>
              <p className='m-0 d-flex align-items-center'>
                <FontAwesomeIcon className='icons' icon={faPhone} />
                <p className='m-0'>            +918000059470
                </p>
              </p>
            </div>
            <p className='row row-cols-4 icon-div m-0 py-5'>
              <FontAwesomeIcon className='col icons shadow' icon={faInstagram} />
              <FontAwesomeIcon className='col icons shadow' icon={faLinkedinIn} />
              <FontAwesomeIcon className='col icons shadow' icon={faBehance} />
              <FontAwesomeIcon className='col icons shadow' icon={faDribbble} />
            </p>
          </div>
          <div className='form-div shadow'>
          <form className='' onSubmit={handleSubmit(onSubmit)}>
      <p className='blue main-description'>Send a Message</p>
      <div className="form-group row row-cols-1 row-cols-lg-2">
        <div className="col mb-3 mb-lg-0">
          <input
            type="text"
          className="form-control"
            placeholder="First name"
            name='firstName'
            {...register('firstName', { required: 'First name is required' })}
          />
          {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
        </div>
        <div className="col">
        <input
          type="email"
          className="form-control"
          placeholder="Enter email"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Please enter a valid email',
            },
          })}
        />
        {errors.email && <p className="text-danger">{errors.email.message}</p>}
        </div>
      </div>
      <div className="form-group">
        <input
          type="tel"
          className="form-control"
          placeholder="Mobile Number"
          {...register('mobileNumber', { required: 'Mobile number is required' })}
        />
        {errors.mobileNumber && <p className="text-danger">{errors.mobileNumber.message}</p>}
      </div>
      <div className="form-group">
      <input
            type="text"
            className="form-control"
            placeholder="Subject"
            {...register('subject', { required: 'Subject is required' })}
          />
          {errors.subject && <p className="text-danger">{errors.subject.message}</p>}
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          rows="3"
          placeholder='Write Your Message Here..'
          {...register('message', { required: 'Message is required' })}
        ></textarea>
        {errors.message && <p className="text-danger">{errors.message.message}</p>}
      </div>
      <button type="submit" className="btn rounded-pill text-center border-0 py-2 text-white">Submit</button>
    </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FormSection